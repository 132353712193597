import React, { useCallback, useEffect, useState } from "react";
import BasicScreen from "../components/BasicScreen";
import SmallText from "../components/SmallText";
import { useForm, UseFormRegister } from "react-hook-form";
import Text from "../components/Text";
import { PageProps } from "gatsby";

const maxLength = 500;
const errorText = "text-sm text-white ";

const Error = () => (
  <div className="mb-4">
    <span className={errorText}>Obavezno polje!</span>
  </div>
);

const MessageField = (props: { register: UseFormRegister<any>; errors: { [x: string]: any } }) => {
  const [length, setLength] = useState(0);
  const handleInput = useCallback(e => {
    const newLength = e?.target?.value?.length ?? 0;
    if (newLength <= maxLength) {
      setLength(newLength);
    } else {
      e.target.value = e.target.value.substring(0, 500);
    }
  }, []);
  return (
    <div className="flex flex-col">
      <textarea
        {...props?.register?.("message", { required: true })}
        placeholder="Poruka"
        maxLength={maxLength}
        rows={6}
        onInput={handleInput}
      ></textarea>
      <SmallText class="self-end -mt-2 text-brown">
        {length}/{maxLength}
      </SmallText>
      {props?.errors?.message && <Error />}
    </div>
  );
};

export const ContactSection = () => {
  const [alertMessage, setAlert] = useState<string | null>(null);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = useCallback((data: any) => {
    console.log(data);

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    var urlencoded = new URLSearchParams();
    urlencoded.append("from", "office@gutenbergovpark.com");
    urlencoded.append("subject", "Indeco Group upit");
    urlencoded.append(
      "text",
      `E-mail: ${data?.email}\nIme:${data?.firstname}\nPrezime:${data?.lastname}\nBroj telefona:${data?.phone}\n\n${data?.message}`,
    );
    urlencoded.append(
      "html",
      `<p>E-mail: ${data?.email}</p>\n<p>Ime:${data?.firstname}</p>\n<p>Prezime:${data?.lastname}</p>\n<p>Broj telefona:${data?.phone}</p>\n\n<p>${data?.message}</p>`,
    );
    urlencoded.append("to", "office@indeco-group.com");
    urlencoded.append("replyTo", "office@gutenbergovpark.com");

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
      redirect: "follow",
    };

    fetch("https://admin.gutenbergovpark.com/email", requestOptions as any)
      .then(response => response.text())
      .then(() => {
        reset();
        setAlert("Upit je poslat!");
      })
      .catch(() => {
        setAlert("Greška");
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let timeoutID: NodeJS.Timeout | null = null;
    if (alertMessage !== null) {
      setTimeout(() => {
        setAlert(null);
      }, 1000);
    }
    return () => {
      timeoutID && clearTimeout(timeoutID);
    };
  }, [alertMessage]);

  return (
    <>
      <div className="hidden md:block absolute top-22 left-3/4 w-1/4 h-28 bg-blue -z-10" />
      <div className="flex w-full p-4 md:pr-0 flex-col md:mt-22 ">
        <div className="bg-blue p-4  w-full flex flex-col ">
          <span className="mb-2 text-2xl">Kontakt</span>
          <SmallText class="font-bold">“INDECO GROUP” DOO SUBOTICA</SmallText>
          <SmallText>Banijaska 4a, objekat “B”, lokal 11</SmallText>
          <SmallText>24 000 Subotica</SmallText>
          <SmallText>PIB: 112509697</SmallText>
          <SmallText>MATICNI BROJ: 21689793</SmallText>
          <SmallText>KONTAKT TELEFON: 069/ 300 1500</SmallText>
          <SmallText>E-mail: office@indeco-group.com</SmallText>

          <form className="mt-6 w-full" method="post" onSubmit={handleSubmit(onSubmit)}>
            <input {...register("firstname", { required: true })} type="text" placeholder="Ime" />
            {errors.firstname && <Error />}
            <input {...register("lastname", { required: true })} type="text" placeholder="Prezime" />
            {errors.lastname && <Error />}
            <input {...register("email", { required: true })} type="text" placeholder="E-mail" />
            {errors.email && <Error />}
            <input {...register("phone", { required: true })} type="text" placeholder="Broj telefona" />
            {errors.phone && <Error />}
            <MessageField register={register} errors={errors} />

            <button className="border-yellow-400 border p-2 text-yellow-400">POŠALJITE UPIT &gt;</button>
          </form>
        </div>

        {alertMessage !== null && (
          <div className="fixed top-0 bottom-0 left-0 right-0 flex flex-col justify-center items-center">
            <div className="bg-white p-10">
              <Text class="text-blue">{alertMessage}</Text>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

const ContactPage = (props: PageProps) => {
  return (
    <BasicScreen {...props}>
      <ContactSection />
    </BasicScreen>
  );
};

export default ContactPage;
